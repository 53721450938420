import styled from 'styled-components'
import css from '@styled-system/css'
import { Box } from '@mintuz/marvel'

export const Pill = styled(Box)`
  border-radius: 16px;

  ${({ noShadow }) =>
    !noShadow ? '  box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.2);' : ''}

  ${css({
    padding: [4],
  })}
`

Pill.defaultProps = {
  backgroundColor: 'rgb(255, 255, 255)'
}
