import React from 'react';
import styled from 'styled-components'

const LogoStyled = styled.div`
    width: 64px;
    height: 64px;
`

export const Logo = () => {
    return (
        <LogoStyled>
            <svg width="100%" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="1024" height="1024" fill="#4C35CA"/>
                <rect x="83.968" y="467.968" width="856.064" height="88.064" rx="40" fill="white"/>
                <rect x="733.136" y="360.4" width="100.448" height="303.2" rx="38" fill="white" stroke="#4C35CA" stroke-width="4"/>
                <rect x="632.784" y="282.576" width="100.448" height="459.872" rx="38" fill="white" stroke="#4C35CA" stroke-width="4"/>
                <rect x="290.864" y="664.624" width="100.448" height="303.2" rx="38" transform="rotate(-180 290.864 664.624)" fill="white" stroke="#4C35CA" stroke-width="4"/>
                <rect x="391.216" y="742.448" width="100.448" height="459.872" rx="38" transform="rotate(-180 391.216 742.448)" fill="white" stroke="#4C35CA" stroke-width="4"/>
            </svg>
        </LogoStyled>
    );
}
