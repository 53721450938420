import React from 'react';
import styled from 'styled-components'
import css from '@styled-system/css'

export const HeaderStyled = styled.header`
    position: relative;
    width: 100%;
    height: 60vh;
    min-height: 400px;
    text-align: center;
    margin-bottom: 100px;

    ${css({
        backgroundColor: 'purple',
        paddingLeft: [4],
        paddingRight: [4],
    })}

    &:after {
        position: absolute;
        bottom: -99px;
        right: 0;
        left: 0;
        content: ' ';
        height: 100px;
        clip-path: polygon(0 0, 100% 0, 100% 0, 0% 100%);

        ${css({
            backgroundColor: 'purple',
        })}
    }
`

export const HeaderContentStyled = styled.div`
    width: 100%;
    max-width: 500px;

    ${css({
        color: 'white',
    })}
`
