import React from 'react';
import styled from 'styled-components'
import css from '@styled-system/css'
import { Link, graphql, useStaticQuery } from "gatsby"
import { Grid, GridItem, Typography, Box, HorizontalList } from '@mintuz/marvel'
import { GhostButton } from '../components/GhostButton'
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { HeaderStyled, HeaderContentStyled } from '../components/HeaderStyled';
import { Logo } from '../components/Logo';
import { Pill } from '../components/Pill';

const GridWrap = styled.div`
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;

    ${css({
        paddingLeft: [4],
        paddingRight: [4],
    })}
`

const GridWrapSmall = styled(GridWrap)`
    max-width: 700px;
`

const WorkoutList = styled(Grid)`
    list-style-type: none;
`

const Tag = styled.div`
    display: inline-block;
    border-radius: 16px;

    ${css({
        paddingTop: [2],
        paddingBottom: [2],
        paddingLeft: [4],
        paddingRight: [4],
        backgroundColor: 'purple',
        color: 'white',
        fontSize: [0],
    })}
`

export default ({data}) => {
    const { workoutsJson: workoutData, site:{ siteMetadata: { siteUrl } }, allFile } = data;

    let image = false

    console.log(allFile)

    // TODO: hack for now
    try {
        image = allFile.edges[0].node.publicURL;
    } catch(e) {
        image = false;
    }

    return (
        <Layout>
            <SEO
                title={workoutData.name}
                description={workoutData.meta.description}
                keywords={workoutData.meta.keywords}
                url={`${siteUrl}/workouts${workoutData.slug}`}
                image={image}
            />
            <HeaderStyled>
                <GridWrap>
                    <Grid>
                        <GridItem width={[1 / 1]}>
                            <Box pt={[[4]]} display="flex" justifyContent="space-between" alignItems="center">
                                <Link to="/"><Logo /></Link>
                                <GhostButton as={Link} to="/workouts" variant="secondary">Free Workouts</GhostButton>
                            </Box>
                        </GridItem>
                        <GridItem width={[1 / 1]}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                                <HeaderContentStyled color="white">
                                    <Typography as="h1" variant="displayOne" mb={[6]}>{workoutData.name}</Typography>
                                    {workoutData.description && <Typography as="p" variant="displayThree" mb={[6]}>{workoutData.description}</Typography>}
                                    {workoutData.tags &&<HorizontalList>{workoutData.tags.map((tag) => <Box as="li" mr={[4]}>{tag}</Box>)}</HorizontalList>}
                                </HeaderContentStyled>
                            </Box>
                        </GridItem>
                    </Grid>
                </GridWrap>
            </HeaderStyled>
            <Box pt="100px" pb={[6]}>
                <GridWrapSmall>
                    <WorkoutList as="ul">
                        {
                            workoutData.exercises.map((exercise) => {
                                return (
                                    <GridItem width={1 / 1}>
                                        <Box mb={[6]}>
                                            <Pill>
                                                <Typography as="h2" mt={[0]} mb={[4]} variant="displayThree">
                                                    {exercise.name}
                                                </Typography>
                                                {exercise.targets && exercise.targets.length > 0 && (
                                                    <Box>
                                                        <Typography as="h3" display="inline-block" mt={[0]} mb={[4]} mr={[4]} variant="body" fontWeight="bold">Targets</Typography>
                                                        <HorizontalList as="ul" display="inline-block">
                                                            {exercise.targets.map((target) => <Box as="li" mr={[4]} mb={[4]}><Tag>{target}</Tag></Box>)}
                                                        </HorizontalList>
                                                    </Box>
                                                )}
                                                {exercise.description && <Typography as="p" mt={[0]} mb={[2]} variant="body">{exercise.description}</Typography>}
                                                {exercise.instructions && exercise.instructions.length > 0 && (
                                                    <>
                                                        <Typography as="h3" mt={[0]} mb={[2]} variant="body" fontWeight="bold">
                                                            Instructions
                                                        </Typography>
                                                        <Pill backgroundColor="lightGrey" noShadow>
                                                            <Typography as="p" variant="body" mb={[4]}><strong>Reps</strong>: {exercise.reps} <strong>Sets</strong>: {exercise.sets}</Typography>
                                                            <ol>
                                                                {exercise.instructions.map((instruction) => <li>{instruction}</li>)}
                                                            </ol>
                                                        </Pill>
                                                    </>
                                                )}
                                            </Pill>
                                        </Box>
                                    </GridItem>
                                );
                            })
                        }
                    </WorkoutList>
                </GridWrapSmall>
            </Box>
        </Layout>
    )
}

export const pageQuery = graphql`
    query WorkoutBySlug($slug: String!, $imageName: String!) {
        site {
            siteMetadata {
                siteUrl
            }
        }
        allFile(filter: {name: {eq: $imageName}, extension: {in: ["jpg", "png"]}}) {
            edges {
                node {
                    publicURL
                }
            }
        }
        workoutsJson(slug: {eq: $slug}) {
            name
            slug
            meta {
                keywords
                description
            }
            description
            tags
            exercises {
                name
                rest
                sets
                reps
                targets
                description
                instructions
                equipment {
                    name
                    description
                    affiliateLink
                }
            }
        }
    }
`;
